export interface FadeData {
  fadeInDuration: number;
  fadeOutDuration: number;
}

export enum AUDIO_PLAYER_EVENT {
  ON_PLAY = 'ON_PLAY',
  ON_PAUSE = 'ON_PAUSE',
  ON_END = 'ON_END',
  ON_STOP = 'ON_STOP',
}

export interface AudioPlayerObject {
  audioUrl: string;
  trim: TrimData;
  fade: FadeData;
  originalDuration: number;
  playCycles: number;
  volume: number;
  speed: number;
}

export interface TrimData {
  isTrimmed: boolean;
  startTime: number;
  endTime: number;
}
