/* eslint-disable no-underscore-dangle */
import type {ImageProps, ObjectEvents, SerializedImageProps, TClassProperties, TOptions} from '@postermywall/fabricjs-2';
import {getFilterBackend, classRegistry, FabricImage, util} from '@postermywall/fabricjs-2';

export const spriteDefaultValues: Partial<TClassProperties<Sprite>> = {};

interface UniqueSpriteProps {
  frameTime: number;
  frameImages: Array<HTMLImageElement>;
}

export interface SerializedSpriteProps extends SerializedImageProps {}

export interface SpriteProps extends ImageProps, UniqueSpriteProps {}

export class Sprite<
    Props extends TOptions<SpriteProps> = Partial<SpriteProps>,
    SProps extends SerializedSpriteProps = SerializedSpriteProps,
    EventSpec extends ObjectEvents = ObjectEvents,
  >
  extends FabricImage<Props, SProps, EventSpec>
  implements SpriteProps
{
  declare frameTime: number;
  declare frameImages: Array<HTMLImageElement>;

  private currentFrameIndex = 0;
  private paused = true;
  private animationInterval?: NodeJS.Timeout;

  static type = 'Sprite';

  static ownDefaults = spriteDefaultValues;

  static getDefaults(): Record<string, any> {
    return {
      ...super.getDefaults(),
      ...Sprite.ownDefaults,
    };
  }

  /**
   * Constructor
   */
  constructor(imageElement: HTMLImageElement, options: Props) {
    if (options.frameTime === undefined || options.frameImages === undefined) {
      throw new Error('options should be given the frameTime and frameImages');
    }

    super(imageElement, options);
    Object.assign(this, Sprite.ownDefaults);
    this.ignoreApplyFilters = true;
    this.setOptions(options);

    this.animationInterval = setInterval((): void => {
      if (!this.paused) {
        this.dirty = true;
        this.currentFrameIndex += 1;
        if (this.currentFrameIndex === this.frameImages.length) {
          this.currentFrameIndex = 0;
        }
      }
    }, this.frameTime);
  }

  _render(ctx: CanvasRenderingContext2D): void {
    const elementToDraw = this.applySpriteFilter(this.frameImages[this.currentFrameIndex]);
    ctx.drawImage(elementToDraw, -this.width / 2, -this.height / 2);
  }

  public play(): void {
    if (this.paused) {
      this.paused = false;
    }
  }

  public stop(): void {
    this.paused = true;
    this.currentFrameIndex = 0;
  }

  public pause(): void {
    this.paused = true;
  }

  public getCurrentTime(): number {
    return Math.round((((this.currentFrameIndex * this.frameTime) / 1000) * 1000) / 1000);
  }

  public seek(time: number): void {
    const spriteIndex = Math.floor((time * 1000) / this.frameTime);
    if (spriteIndex < this.frameImages.length) {
      this.currentFrameIndex = spriteIndex;
    }
  }

  public onRemove(): void {
    clearInterval(this.animationInterval);
    this.animationInterval = undefined;
  }

  private applySpriteFilter(elementToDraw: HTMLImageElement): HTMLCanvasElement | HTMLImageElement {
    let filters = this.filters || [];
    filters = filters.filter((filter) => {
      return filter;
    });

    if (filters.length === 0) {
      this._element = elementToDraw;
      this._filteredEl = undefined;
      this._filterScalingX = 1;
      this._filterScalingY = 1;
      return this._element;
    }

    const imageEl = elementToDraw;
    const sourceWidth = imageEl.naturalWidth || imageEl.width;
    const sourceHeight = imageEl.naturalHeight || imageEl.height;

    const canvasEl = util.createCanvasElement();
    canvasEl.width = sourceWidth;
    canvasEl.height = sourceHeight;
    this._element = canvasEl;
    this._filteredEl = canvasEl;

    getFilterBackend().applyFilters(filters, elementToDraw, sourceWidth, sourceHeight, this._element);
    if (this._originalElement.width !== this._element.width || this._originalElement.height !== this._element.height) {
      this._filterScalingX = this._element.width / this._originalElement.width;
      this._filterScalingY = this._element.height / this._originalElement.height;
    }
    this._element = imageEl;
    return canvasEl;
  }
}

classRegistry.setClass(Sprite);
