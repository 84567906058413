import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {FillObject, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {RGB} from '@Utils/color.util';
import {LinePointsStyle, LineDash} from '@PosterWhiteboard/items/line-item/pmw-fabric-line';
import type {CustomControl, OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {renderControl} from '@PosterWhiteboard/poster/poster-item-controls';
import {Control, controlsUtils, Polyline, type TPointerEvent, type Transform} from '@postermywall/fabricjs-2';

export interface LineItemObject extends BaseItemObject {
  fill: FillObject;
  lineDash: LineDash;
  lineEnd: LinePointsStyle;
  lineStart: LinePointsStyle;
}

const MIN_HEIGHT = 40;

export class LineItem extends Item {
  declare fabricObject: Polyline;
  public width = 300;
  public height = MIN_HEIGHT;
  public gitype: ITEM_TYPE.LINE = ITEM_TYPE.LINE;
  public fill: Fill;
  public thickness = 4;
  public lineDash: LineDash = LineDash.NONE;
  public lineEnd: LinePointsStyle = LinePointsStyle.NONE;
  public lineStart: LinePointsStyle = LinePointsStyle.NONE;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill();
  }

  public async isPDFSafe(): Promise<boolean> {
    return true;
  }

  public hasClickableLink(): boolean {
    return false;
  }

  public toObject(): LineItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      lineDash: this.lineDash,
      lineEnd: this.lineEnd,
      lineStart: this.lineStart,
    };
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    this.fabricObject.setControlsVisibility({
      tl: false,
      tr: false,
      bl: false,
      br: false,
      mr: false,
      ml: false,
      lineMl: !this.isLocked(),
      lineMr: !this.isLocked(),
    });
  }

  protected initCustomControls(): void {
    // super.initCustomControls();
    // const lineMlControl = this.getLineMLControl(this.onResizeWithLeftHandle.bind(this));
    // const lineMrControl = this.getLineMrControl(this.onResizeWithRightHandle.bind(this));
    // this.fabricObject.controls[lineMlControl.key] = lineMlControl.control;
    // this.fabricObject.controls[lineMrControl.key] = lineMrControl.control;
    //
    this.fabricObject.set({
      controls: controlsUtils.createPolyControls(this.fabricObject),
      cornerStyle: 'circle',
      cornerSize: 16,
      cornerStrokeColor: '#E6E6EC',
      cornerColor: '#ffffff',
    });
  }

  private onResizeWithLeftHandle(event: OnResizeParams): void {
    console.log(event);
  }

  private onResizeWithRightHandle(event: OnResizeParams): void {
    console.log(event);
  }

  private getLineMLControl(onResize: (params: OnResizeParams) => void): CustomControl {
    const key = 'lineMl';
    return {
      key,
      control: new Control({
        x: -0.5,
        y: 0,
        visible: false,
        cursorStyleHandler: (): string => {
          return 'crosshair';
        },
        actionName: 'resize',
        render: renderControl.bind(this, key),
        actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
          if (!transformData.corner) {
            return false;
          }

          onResize({e, delta: 5});
          return true;
        },
      }),
    };
  }

  private getLineMrControl(onResize: (params: OnResizeParams) => void): CustomControl {
    const key = 'lineMr';
    return {
      key,
      control: new Control({
        x: 0.5,
        y: 0,
        visible: false,
        cursorStyleHandler: (): string => {
          return 'crosshair';
        },
        actionName: 'resize',
        render: renderControl.bind(this, key),
        actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
          if (!transformData.corner) {
            return false;
          }

          onResize({e, delta: 5});
          return true;
        },
      }),
    };
  }

  public updateFillColor(fillColor: Array<RGB>, undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillAlpha,
        },
      },
      {
        undoable,
      }
    );
  }

  protected async getFabricObjectForItem(): Promise<Polyline> {
    const obj = new Polyline(
      [
        {x: 3, y: 30},
        {x: 300, y: 30},
      ],
      {
        hasBorders: true,
        strokeWidth: 1,
        stroke: 'grey',
      }
    );
    obj.set({
      controls: controlsUtils.createPolyControls(obj),
    });
    obj.set(this.getCommonOptions());
    return obj;
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
      thickness: this.thickness,
      strokeWidth: this.thickness,
      stroke: 'grey',
      lineDash: this.lineDash,
      lineEnd: this.lineEnd,
      lineStart: this.lineStart,
    });
  }
}
