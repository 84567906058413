import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {AnimationSpeed, AnimationType} from '@PosterWhiteboard/animation/animation.class';
import type {ItemConfig} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';

const DEFAULT_TRANSITION_COLOR: RGB = [255, 255, 255];

export interface TransitionObject extends ItemConfig {
  type?: AnimationType;
  color?: RGB;
  speed?: AnimationSpeed;
}

export class Transition extends CommonMethods {
  public type = AnimationType.FADE;
  public speed = AnimationSpeed.MEDIUM;
  public color: RGB = DEFAULT_TRANSITION_COLOR;

  public toObject(): TransitionObject {
    return {
      type: this.type,
      speed: this.speed,
      color: this.color,
    };
  }

  public updateFromObject(object: Record<string, any>): void {
    this.copyVals(object);
  }
}
