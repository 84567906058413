import type {TabsItem} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import {IText, Line} from '@postermywall/fabricjs-2';

const EDGE_PADDING = 10;

export class StackLayout {
  public item: TabsItem;

  public constructor(item: TabsItem) {
    this.item = item;
  }

  async doLayout(): Promise<void> {
    const items = this.item.fabricObject.getObjects() as Array<IText | Line>;
    const viewWidth = this.item.fabricObject.width;
    const firstTab = items[0] as IText;
    const itemHeight = firstTab.calcTextHeight();
    const top = -this.item.fabricObject.height / 2 + EDGE_PADDING;
    const textObjects = items.filter((item) => {
      return item instanceof IText;
    });
    const lineObjects = items.filter((item) => {
      return item instanceof Line;
    });

    const numberOfTextItems = textObjects.length;
    const emptySpace = viewWidth - itemHeight * numberOfTextItems;
    const offset = emptySpace / numberOfTextItems;

    for (const [i, textObject] of textObjects.entries()) {
      if (i === 0) {
        textObject.set({
          left: -this.item.fabricObject.width / 2 + itemHeight + offset / 2,
          top,
        });
      } else {
        textObject.set({
          left: items[i - 1].left + offset + itemHeight,
          top,
        });
      }
    }

    for (const [j, lineObject] of lineObjects.entries()) {
      lineObject.set({
        left: (textObjects[j].getRelativeCenterPoint().x + textObjects[j + 1].getRelativeCenterPoint().x) / 2,
        top,
      });
    }
  }
}
