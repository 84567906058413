import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {openPosterEditorItemEffectsModal} from '@Modals/poster-editor-item-effects-modal';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import {ItemEffects} from '@PosterWhiteboard/classes/item-effects.class';
import {SyncToPosterClock} from '@PosterWhiteboard/classes/sync-to-poster-clock.class';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';
import {getFramesForSprite} from '@Libraries/animated-sprite.library';
import {Sprite} from '@PosterWhiteboard/items/fabric-sprite';
import type {DeepPartial} from '@/global';

export interface StickerItemObject extends BaseItemObject {
  hashedFilename: string;
  duration: number;
  frameRate: number;
  effects: ItemEffectsObject;
  highResAnimatedSprite: AnimatedSprite;
  screenAnimatedSprite: AnimatedSprite;
}

/**
 * The version at which we enabled uniform stroke for motion items.
 */
const VERSION_2 = 2;

/**
 * Default min duration for which stickers should be played
 */
const STICKER_MIN_PLAY_DURATION = 10;

export class StickerItem extends Item {
  declare fabricObject: Sprite;
  public gitype: ITEM_TYPE.STICKER = ITEM_TYPE.STICKER;
  public frameRate = 0;
  public hashedFilename = '';
  public duration = 0;
  public effects: ItemEffects;
  public version = 2;
  public htmlImageElement!: HTMLImageElement;
  public syncToPosterClock: SyncToPosterClock;
  public highResAnimatedSprite!: AnimatedSprite;
  public screenAnimatedSprite!: AnimatedSprite;
  /**
   * Sync to poster click function is cached in this variable so that it can be unmounted on item remove. Inline use of that
   * function doesn't work because of bind (https://stackoverflow.com/questions/28800850/jquery-off-is-not-unbinding-events-when-using-bind)
   */
  private readonly syncToPosterClockFunction;

  public constructor(page: Page) {
    super(page);
    this.effects = new ItemEffects(this);
    this.syncToPosterClock = new SyncToPosterClock({
      getDuration: this.getDuration.bind(this),
      getCurrentTime: this.getCurrentTime.bind(this),
      seek: this.seek.bind(this),
      pauseAtEnd: false,
    });
    this.syncToPosterClockFunction = this.syncToPosterClock.syncToPage.bind(this.syncToPosterClock);
  }

  public getPlayDuration(): number {
    return Math.max(this.getDuration(), STICKER_MIN_PLAY_DURATION);
  }

  public async getFabricObjectForItem(): Promise<Sprite> {
    const currentSprite = this.getCurrentSprite();

    const frameImages = await getFramesForSprite(currentSprite);
    if (!frameImages[0]) {
      throw new Error(`No frame images for sprite: ${JSON.stringify(currentSprite)}`);
    }
    [this.htmlImageElement] = frameImages;
    this.scaleItem();
    return new Sprite(this.htmlImageElement, {
      ...super.getCommonOptions(),
      frameImages,
      frameHeight: this.htmlImageElement.height,
      frameWidth: currentSprite.frameWidth,
      frameTime: 1000 / currentSprite.frameRate,
    });
  }

  public getDuration(): number {
    return this.duration;
  }

  public getCurrentTime(): number {
    return this.fabricObject.getCurrentTime();
  }

  public async seek(time: number): Promise<void> {
    const seekTime = time % this.getDuration();
    this.fabricObject.seek(seekTime);
  }

  public async stop(): Promise<void> {
    this.fabricObject.stop();
  }

  public async play(): Promise<void> {
    this.fabricObject.play();
  }

  public async pause(): Promise<void> {
    this.fabricObject.pause();
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    this.fabricObject.setControlsVisibility({
      ml: !this.isLocked(),
      mt: !this.isLocked(),
      mr: !this.isLocked(),
      mb: !this.isLocked(),
    });
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  public copyVals(obj: DeepPartial<StickerItemObject>): void {
    const {effects, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.effects.copyVals(effects);
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    await this.effects.applyItemEffects();
    await this.effects.applyBorderEffects();
  }

  protected fixChanges(): void {
    super.fixChanges();
    this.applyFixForVersion2();
  }

  private applyFixForVersion2(): void {
    if (!this.hasUniformStroke()) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * this.scaleX);
      this.version = VERSION_2;
    }
  }

  public onRemove(): void {
    super.onRemove();
    this.fabricObject.onRemove();
    this.page.poster.off(PosterEvent.PAGE_TIME_UPDATED, this.syncToPosterClockFunction);
  }

  public async onItemAddedToPage(): Promise<void> {
    this.page.poster.on(PosterEvent.PAGE_TIME_UPDATED, this.syncToPosterClockFunction);

    if (this.page.background.isTransparentBackground()) {
      this.page.background.updateBackgroundToBeNonTransparent();
    }
    if (this.page.poster.isPlaying()) {
      await this.play();
    }
  }

  public hasUniformStroke(): boolean {
    return this.version >= VERSION_2;
  }

  /**
   * Scale image only if an already added image is loaded with a different dimension than it was the previous time loaded and saved
   */
  private scaleItem(): void {
    if (this.width && this.height && this.hasDifferentDimensions()) {
      this.scaleX = (this.scaleX * this.width) / this.getLoaderDisplayWidth();
      this.scaleY = (this.scaleY * this.height) / this.getLoaderDisplayHeight();
    }
  }

  private hasDifferentDimensions(): boolean {
    return this.width !== this.getLoaderDisplayHeight() || this.height !== this.getLoaderDisplayWidth();
  }

  protected getLoaderDisplayWidth(): number {
    return this.htmlImageElement.width;
  }

  protected getLoaderDisplayHeight(): number {
    return this.htmlImageElement.height;
  }

  private getCurrentSprite(): AnimatedSprite {
    if (this.page.poster.isHighRes) {
      return this.highResAnimatedSprite;
    }

    return this.screenAnimatedSprite;
  }

  public toObject(): StickerItemObject {
    return {
      ...super.toObject(),
      effects: this.effects.toObject(),
      hashedFilename: this.hashedFilename,
      duration: this.duration,
      frameRate: this.frameRate,
      highResAnimatedSprite: this.highResAnimatedSprite,
      screenAnimatedSprite: this.screenAnimatedSprite,
    };
  }

  protected onItemDoubleTapped(): void {
    openPosterEditorItemEffectsModal();
  }
}
