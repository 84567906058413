import {Item} from '@PosterWhiteboard/items/item/item.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';
import {colorToRGB, rgbToHexString} from '@Utils/color.util';
import {cloneAsImageWithAngleIgnored, getPaths} from '@Utils/fabric.util';
import {degToRad} from '@Utils/math.util';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {VectorItemSource} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import {arePathsCustomizable, loadVectorSVG} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {Fill, FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import type {FabricObject, GradientOptions} from '@postermywall/fabricjs-2';
import {util} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

const VECTOR_SHADOW_DISTANCE = 2;
const MAX_VECTOR_PATHS = 200;
const MAX_SINGLE_PATH_OUTLINE_THICKNESS = 100;
const MAX_MULTI_PATH_OUTLINE_THICKNESS = 40;

interface VectorPositionFixVals {
  a: number;
  b: number;
  c: number;
  d: number;
}

enum ShapeVersions {
  /**
   * Version of shape where we fixed vector viewport by letting fabric decide its dimensions
   * rather than ourselves
   */
  VERSION_VIEWPORT_FIX = 2,
  /**
   * Version of shape when uniform stroke was fixed for user shapes, and we changed the single path check.
   */
  VERSION_3 = 3,
  /**
   * Version of shape when uniform stroke was changed to use fabric uniformStroke flag instead of manually changing it for multipath vectors
   */
  VERSION_4 = 4,
  /**
   * Version of shape when opacity was changed to be applied to the whole shape and not only fill
   */
  VERSION_5 = 5,
}

export class VectorItem extends Item {
  public gitype = ITEM_TYPE.VECTOR;
  public source!: VectorItemSource;
  public fileName = '';
  public fill: Fill;

  public isCustomisable = true;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill({
      getRadialGradientOpts: getRadialGradientOptsForVectorItem,
    });
  }

  public toObject(): VectorItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      fileName: this.fileName,
      source: this.source,
      isCustomisable: this.isCustomisable,
    };
  }

  public async initFabricObject(): Promise<void> {
    await super.initFabricObject();
    await this.onItemInitialized();
  }

  protected async onItemInitialized(): Promise<void> {
    if (this.hasTooManyPaths(this.fabricObject)) {
      await this.convertVectorToImage();
    }
    await this.updateFabricObject();
  }

  protected isVisible(): boolean {
    const isVisible = super.isVisible();
    return isVisible && ((this.fill.hasFill() && this.alpha > 0) || this.border.solidBorderThickness > 0);
  }

  protected async getFabricObjectForItem(): Promise<FabricObject> {
    const {paths} = await loadVectorSVG(this.fileName, this.source);
    const opts = {
      ...this.getInitOptionsForView(),
    };
    this.initValsFromPaths(paths);
    this.preparePaths(paths);

    const svg = util.groupSVGElements(paths, opts);
    this.patchScale(svg);
    svg.set({
      uniformScaling: !(this.fileName === '803' || this.fileName === '804'),
    });
    return svg;
  }

  /**
   * This patches older version of vectors where width in model was different from the fabric object one. This recalculates scale
   * so that the total scaledWidth remains the same onces we set the model with back to the one fabricObject has
   */
  private patchScale(svg: FabricObject): void {
    if (this.width) {
      this.scaleX = this.getScaledWidth() / svg.width;
      this.scaleY = this.getScaledHeight() / svg.height;
    }
  }

  private initValsFromPaths(paths: Array<FabricObject>): void {
    const isNew = !this.width || this.width < 0;

    if (isNew) {
      if (paths.length > 0) {
        this.border.solidBorderType = BorderType.RECTANGLE_BORDER;
        this.border.solidBorderThickness = paths[0].strokeWidth * this.scaleX;
        if (arePathsCustomizable(paths)) {
          this.fill.fillColor[0] = colorToRGB(paths[0].fill);
        }
        const stroke = colorToRGB(paths[0].stroke);
        if (stroke !== null) {
          this.border.solidBorderColor = stroke;
        }
      }
      this.initializeFillType(paths);
    }

    if (!arePathsCustomizable(paths)) {
      this.isCustomisable = false;
    }
  }

  protected setControlsVisibility(): void {
    const shouldHideControlsOnSmallItem = this.shouldHideControlOnSmallItem();

    super.setControlsVisibility();
    this.fabricObject.setControlsVisibility({
      ml: !this.isLocked() && !shouldHideControlsOnSmallItem,
      mt: !this.isLocked(),
      mr: !this.isLocked() && !shouldHideControlsOnSmallItem,
      mb: !this.isLocked(),
    });
  }

  protected hasSinglePath(): boolean {
    if (this.version >= ShapeVersions.VERSION_3) {
      return this.fabricObject.type !== 'group';
    }

    // Old way of checking if a vector is single path or not
    return this.fabricObject.type === 'path';
  }

  protected getMaxOutlineThickness(): number {
    return this.hasSinglePath() ? MAX_SINGLE_PATH_OUTLINE_THICKNESS : MAX_MULTI_PATH_OUTLINE_THICKNESS;
  }

  public copyVals(obj: DeepPartial<VectorItemObject>): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.setFill();
    this.applyFixForVersion4();
    await this.applyFixForVersion5();
  }

  public applyBorder(): void {
    if (this.isCustomisable) {
      const paths = getPaths(this.fabricObject);
      const strokeHex = `${rgbToHexString(this.border.solidBorderColor)}`;

      for (let i = 0; i < paths.length; i++) {
        paths[i].set({
          stroke: strokeHex,
        });
      }

      this.fabricObject.set({
        ...this.border.getBorder(),
      });
    }
  }

  public getColors(): Array<RGB> {
    let colors: Array<RGB> = super.getColors();

    if (this.fill.hasFill()) {
      colors = [...colors, ...this.fill.fillColor];
    }

    colors.push(this.border.solidBorderColor);

    return colors;
  }

  protected itemObjectHasDestructiveChanges(oldItemObject: VectorItemObject): boolean {
    if (!this.fabricObject) {
      return false;
    }

    if (this.isCustomisable && !this.hasSinglePath()) {
      if (oldItemObject.border.solidBorderThickness !== this.border.solidBorderThickness) {
        return true;
      }
    }
    return false;
  }

  protected getInitOptionsForView(): Record<string, any> {
    return {
      perPixelTargetFind: true,
      selectable: true,
      lockUniScaling: false,
    };
  }

  private preparePaths(paths: Array<FabricObject>): void {
    if (this.isCustomisable) {
      for (const path of paths) {
        path.set({
          strokeWidth: this.border.solidBorderThickness,
          strokeUniform: true,
        });
      }
    }
  }

  private initializeFillType(paths: Array<FabricObject>): void {
    if (paths.length > 0 && !paths[0].fill) {
      for (let i = 0; i < paths.length; i++) {
        if (paths[i].fill) {
          return;
        }
      }
      this.fill.fillType = FillTypes.NONE;
    }
  }

  private applyFixForVersion4(): void {
    if (!this.isMultipathVectorUniformStrokeFixed() && !this.hasSinglePath()) {
      // Set the new x/y to item. Apply strokeweight offset as that is now included in fabric object calculations too
      const coords = this.fabricObject.calcACoords();
      const cosOfAngle = Math.cos(degToRad(this.rotation));
      const sinOfAngle = Math.sin(degToRad(this.rotation));
      const cosVal = (cosOfAngle * this.border.solidBorderThickness) / 2;
      const sinVal = (sinOfAngle * this.border.solidBorderThickness) / 2;

      // I honestly don't know why i had to use that combination of cos and sin. Just came up with it by hit and trial
      this.x = coords.tl.x + cosVal - sinVal;
      this.y = coords.tl.y + cosVal + sinVal;

      this.fabricObject.set({
        originX: 'left',
        originY: 'top',
        left: this.x,
        top: this.y,
      });

      this.version = ShapeVersions.VERSION_4;
    }
  }

  private isMultipathVectorUniformStrokeFixed(): boolean {
    return this.version >= ShapeVersions.VERSION_4;
  }

  private doesOpacityWorkOnStroke(): boolean {
    return this.version >= ShapeVersions.VERSION_5;
  }

  private async applyFixForVersion5(): Promise<void> {
    if (!this.doesOpacityWorkOnStroke()) {
      if (this.isCustomisable) {
        this.fill.fillAlpha = this.alpha;
      }
      this.alpha = 1;
      this.version = ShapeVersions.VERSION_5;
      await this.updateFabricObject();
    }
  }

  private hasTooManyPaths(svg: FabricObject): boolean {
    return getPaths(svg).length > MAX_VECTOR_PATHS;
  }

  public hasMultiplePaths(): boolean {
    return getPaths(this.fabricObject).length > 1;
  }

  protected getOldShadowDistance(): number {
    return 2;
  }

  protected fixChanges(): void {
    super.fixChanges();
    if (this.page.poster.version < POSTER_VERSION.FABRIC_2_UPDATE) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * Math.max(this.scaleX, this.scaleY));
    }

    if (this.page.poster.version < POSTER_VERSION.FABRIC_3_UPDATE) {
      switch (this.fileName) {
        case '38':
        case '41':
        case '28':
        case '34':
        case '35':
        case '30':
        case '50':
        case '10':
        case '9':
          this.applyVectorPositionFix({
            a: -0.013468013468013,
            b: -0.23878787878788,
            c: 1.9692929292929,
            d: -0.32727272727273,
          });
          break;

        case '12':
        case '11':
        case '24':
        case '7':
        case '8':
          this.applyVectorPositionFix({
            a: -0.019418426691154,
            b: -0.099467401285583,
            c: 1.0419467401286,
            d: -1.1844628099174,
          });
          return;

        case '33':
        case '32':
        case '36':
        case '20':
        case '6':
          this.applyVectorPositionFix({
            a: -0.1724436282012,
            b: -0.058475665748393,
            c: 3.4995041322314,
            d: 1.1639669421488,
          });
          break;

        default:
          break;
      }
    }
  }

  private applyVectorPositionFix(offsets: VectorPositionFixVals): void {
    // equation is = (a*scale + b) * stroke  + c * scale + d
    const xCoorDelta = Math.round((offsets.a * this.scaleX + offsets.b) * this.border.solidBorderThickness + offsets.c * this.scaleX + offsets.d);
    const yCoorDelta = Math.round((offsets.a * this.scaleY + offsets.b) * this.border.solidBorderThickness + offsets.c * this.scaleY + offsets.d);
    let xcor1 = -yCoorDelta * Math.sin((this.rotation * Math.PI) / 180);
    let ycor1 = yCoorDelta * Math.cos((this.rotation * Math.PI) / 180);

    xcor1 += xCoorDelta * Math.cos((this.rotation * Math.PI) / 180);
    ycor1 += xCoorDelta * Math.sin((this.rotation * Math.PI) / 180);

    this.x += xcor1;
    this.y += ycor1;
  }

  private setFill(): void {
    if (this.isCustomisable) {
      const paths = getPaths(this.fabricObject);

      for (let i = 0; i < paths.length; i++) {
        paths[i].set({
          fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
        });
      }
    }
  }

  private async convertVectorToImage(): Promise<void> {
    this.setFill();
    this.fabricObject.set({
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    });
    this.applyBorder();
    const img = await cloneAsImageWithAngleIgnored(this.fabricObject, {
      multiplier: this.page.poster.scaling.scale,
    });
    if (!img.width || !img.height) {
      throw new Error('Failed to get image for vector');
    }

    this.scaleX *= this.fabricObject.get('width') / img.width;
    this.scaleY *= this.fabricObject.get('height') / img.height;
    this.setFabricObject(img);
    this.isCustomisable = false;
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillAlpha,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillColor(fillColor: Array<RGB>, undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }
}

const getRadialGradientOptsForVectorItem = (fillWidth: number, fillHeight: number, gradientFillColors: Array<RGB>, alpha = 1): GradientOptions<'radial'> => {
  const x = fillWidth / 2;
  const y = fillHeight / 2;
  const maxDimension = Math.max(x, y);

  return {
    colorStops: [
      {offset: 0, color: rgbToHexString(gradientFillColors[0], alpha)},
      {offset: 0.75, color: rgbToHexString(gradientFillColors[1], alpha)}, // to cater for most SVGs having space between the control box and shape
    ],
    type: 'radial',
    coords: {
      r1: 0.1 * maxDimension * 2,
      r2: maxDimension * 2,
      x1: maxDimension,
      y1: maxDimension,
      x2: maxDimension,
      y2: maxDimension,
    },
  };
};
