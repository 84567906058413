import {CommonMethods} from '@PosterWhiteboard/common-methods';

export enum GENERAL_EVENT_NAMES {
  ALL = 'ALL',
}

export type EVENT_NAME = string | GENERAL_EVENT_NAMES;

export class Observable extends CommonMethods {
  private eventListeners: Record<string, Function[]> = {};

  public on(events: EVENT_NAME[] | EVENT_NAME, handler: Function) {
    const eventNames = Array.isArray(events) ? events : [events];
    for (const eventName of eventNames) {
      if (!this.eventListeners[eventName]) {
        this.eventListeners[eventName] = [];
      }

      this.eventListeners[eventName].push(handler);
    }
  }

  public off(eventName: EVENT_NAME, handler?: Function): void {
    if (!this.eventListeners[eventName]) {
      return;
    }

    if (handler) {
      const eventListener = this.eventListeners[eventName];
      const index = eventListener.indexOf(handler);
      if (index > -1) {
        eventListener.splice(index, 1);
      }
    } else {
      this.eventListeners[eventName] = [];
    }
  }

  fire(eventName: EVENT_NAME, ...args: any[]): void {
    if (!this.eventListeners) {
      return;
    }

    const listenersForEvent = this.eventListeners[eventName]?.concat();
    if (listenersForEvent) {
      for (let i = 0; i < listenersForEvent.length; i++) {
        listenersForEvent[i].call(this, ...args, eventName);
      }
    }

    const listenersForAllEvents = this.eventListeners[GENERAL_EVENT_NAMES.ALL]?.concat();
    if (listenersForAllEvents) {
      for (let i = 0; i < listenersForAllEvents.length; i++) {
        listenersForAllEvents[i].call(this, eventName, ...args);
      }
    }
  }
}
