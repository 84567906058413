import {isClipboardAPISupported} from '@Utils/clipboard.util';

let isClipboardReadSupported: boolean | undefined;

/*
  Source: https://stackoverflow.com/questions/78062109/firefox-navigator-clipboard-readtext-is-not-a-function
  Note: For firefox, the users will manually have to enable the clipboard api permissions for paste to work. They may do it by following these steps:
 1. Open a new tab and on the url input, type: 'about:config' and click on "Accept the Risk and Continue".
 2. In the "Search preference name" bar, type in "dom.events.testing.asyncClipboard" and set that to true by clicking on the switch icon on the right (see attached picture).
 3. Also search for "dom.events.asyncClipboard.clipboardItem" and "dom.events.asyncClipboard.readText" and set them to true.
 4. Refresh your PosterMyWall design, and now try copy/paste.
 */

export const checkClipboardReadPermissions = async (): Promise<void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore, code taken from https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/read
    const result = await navigator.permissions.query({name: 'clipboard-read', allowWithoutGesture: false});
    if (result.state === 'granted') {
      isClipboardReadSupported = true;
    } else if (result.state === 'denied') {
      isClipboardReadSupported = false;
    }
  } catch (e) {
    isClipboardReadSupported = isClipboardAPISupported() && typeof navigator.clipboard.readText === 'function';
  }
};

export const getIsClipboardReadSupported = async (): Promise<boolean> => {
  if (isClipboardReadSupported === undefined) {
    await checkClipboardReadPermissions();
  }
  return isClipboardReadSupported as boolean;
};
