import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {Control} from '@postermywall/fabricjs-2';
import type {CustomControl} from '@PosterWhiteboard/poster/poster-item-controls';
import {renderControl, DEFAULT_CIRCLE_CONTROL_SIZE, ITEM_CONTROL_DIMENSIONS, ItemControlAction, pmwBtnsCursorStyleHandler} from '@PosterWhiteboard/poster/poster-item-controls';

const PREVIOUS_SLIDE_CONTROL_OFFSET_X = Math.round(DEFAULT_CIRCLE_CONTROL_SIZE * 0.25);

export class SlideshowItemCustomControls {
  public slideshow: SlideshowItem;

  public constructor(slideshow: SlideshowItem) {
    this.slideshow = slideshow;
  }

  public getPreviousSlideControl(): CustomControl {
    const key = 'pmwPreviousSlideBtn';
    return {
      key,
      control: new Control({
        x: -0.5,
        y: 0.5,
        offsetY: ITEM_CONTROL_DIMENSIONS.BOTTOM_BUTTONS_OFFSET,
        offsetX: PREVIOUS_SLIDE_CONTROL_OFFSET_X,
        visible: false,
        actionName: ItemControlAction.SLIDE_BTN_ACTION_NAME,
        cursorStyleHandler: pmwBtnsCursorStyleHandler,
        render: renderControl.bind(this, key),
        mouseUpHandler: (): void => {
          this.slideshow.selectPreviousSlide();
        },
      }),
    };
  }

  public getNextSlideControl(): CustomControl {
    const NEXT_SLIDE_BUTTON_MARGIN_LEFT = 0;

    const key = 'pmwNextSlideBtn';
    return {
      key,
      control: new Control({
        x: -0.5,
        y: 0.5,
        offsetY: ITEM_CONTROL_DIMENSIONS.BOTTOM_BUTTONS_OFFSET,
        offsetX: PREVIOUS_SLIDE_CONTROL_OFFSET_X + DEFAULT_CIRCLE_CONTROL_SIZE + NEXT_SLIDE_BUTTON_MARGIN_LEFT,
        visible: false,
        actionName: ItemControlAction.SLIDE_BTN_ACTION_NAME,
        cursorStyleHandler: pmwBtnsCursorStyleHandler,
        render: renderControl.bind(this, key),
        mouseUpHandler: (): void => {
          this.slideshow.selectNextSlide();
        },
      }),
    };
  }
}
