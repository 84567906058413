import type * as Fabric from '@postermywall/fabricjs-2';
import {TextHorizontalAlignType, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import {getScaledManualHorizontalPadding, getScaledManualLeftPadding, getScaledManualTopPadding, getScaledManualVerticalPadding} from '@PosterWhiteboard/items/item/item.library';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import {degreesToRadians} from '@Utils/math.util';
import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import {getGroupChildAbsoluteCornerPoints} from '@Utils/fabric.util';
import {CommonMethods} from '@PosterWhiteboard/common-methods';

export interface MediaSlideObject {
  horizontalAlign: string;
  verticalAlign: string;
}

export class MediaSlide extends CommonMethods {
  public horizontalAlign: Fabric.TOriginX = 'center';
  public verticalAlign: Fabric.TOriginY = 'center';

  public slide: ImageSlideItem | VideoSlideItem;

  constructor(slide: ImageSlideItem | VideoSlideItem) {
    super();
    this.slide = slide;
  }

  public toObject(): MediaSlideObject {
    return {
      horizontalAlign: this.horizontalAlign as string,
      verticalAlign: this.verticalAlign as string,
    };
  }

  public getMediaSlidePosition(): Record<string, any> {
    let left;
    let top;

    const slideshowFabricObject = this.slide.fabricObject.group as Fabric.Group;
    switch (this.horizontalAlign) {
      case TextHorizontalAlignType.LEFT:
        left = -slideshowFabricObject.width / 2 + getScaledManualLeftPadding(slideshowFabricObject.scaleX);
        break;

      case TextHorizontalAlignType.CENTER:
        left = -this.slide.fabricObject.getScaledWidth() / 2;
        break;

      case TextHorizontalAlignType.RIGHT:
        left = slideshowFabricObject.width / 2 - getScaledManualLeftPadding(slideshowFabricObject.scaleX) - this.slide.fabricObject.getScaledWidth();
        break;

      default:
        console.error(`Unknown align type value '${this.horizontalAlign}`);
    }

    switch (this.verticalAlign) {
      case TextVerticalAlignType.TOP:
        top = -slideshowFabricObject.height / 2 + getScaledManualTopPadding(slideshowFabricObject.scaleX);
        break;

      case TextVerticalAlignType.CENTER:
        top = -this.slide.fabricObject.getScaledHeight() / 2;
        break;

      case TextVerticalAlignType.BOTTOM:
        top = slideshowFabricObject.height / 2 - getScaledManualTopPadding(slideshowFabricObject.scaleY) - this.slide.fabricObject.getScaledHeight();
        break;

      default:
        console.error(`Unknown align type value '${this.verticalAlign}`);
    }

    return {
      left,
      top,
    };
  }

  public getMediaSlideAbsolutePosition(): Record<string, any> {
    const slideshowFabricObject = this.slide.fabricObject.group as Fabric.Group;
    let params: Record<string, any>;
    const theta = degreesToRadians(45 + slideshowFabricObject.angle);
    const displacement = Math.sqrt(2 * ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING ** 2);

    if (this.hasHorizontalAlignmentAxis()) {
      const cornerPoints = getGroupChildAbsoluteCornerPoints(this.slide.fabricObject as Fabric.Object);
      switch (this.horizontalAlign) {
        case TextHorizontalAlignType.LEFT:
          params = {
            left: slideshowFabricObject.aCoords.tl.x + displacement * Math.cos(theta),
            top: slideshowFabricObject.aCoords.tl.y + displacement * Math.sin(theta),
          };
          break;

        case TextHorizontalAlignType.CENTER:
          // const cornerPoints = getGroupChildAbsoluteCornerPoints(this.slide.fabricObject as Fabric.Object);
          params = {
            left: (cornerPoints.tl.x + cornerPoints.tr.x) / 2,
            top: (cornerPoints.tl.y + cornerPoints.tr.y) / 2,
          };
          break;

        case TextHorizontalAlignType.RIGHT:
          params = {
            left: slideshowFabricObject.aCoords.tr.x - displacement * Math.sin(theta),
            top: slideshowFabricObject.aCoords.tr.y + displacement * Math.cos(theta),
          };
          break;

        default:
          params = {
            left: slideshowFabricObject.aCoords.tl.x + displacement * Math.cos(theta),
            top: slideshowFabricObject.aCoords.tl.y + displacement * Math.sin(theta),
          };
          console.error(`Unknown align type value '${this.verticalAlign}`);
      }
      params.originX = this.horizontalAlign;
    } else {
      const cornerPoints = getGroupChildAbsoluteCornerPoints(this.slide.fabricObject as Fabric.Object);
      switch (this.verticalAlign) {
        case TextVerticalAlignType.TOP:
          params = {
            left: slideshowFabricObject.aCoords.tl.x + displacement * Math.cos(theta),
            top: slideshowFabricObject.aCoords.tl.y + displacement * Math.sin(theta),
          };
          break;

        case TextVerticalAlignType.CENTER:
          // const cornerPoints = getGroupChildAbsoluteCornerPoints(this.slide.fabricObject as Fabric.Object);
          params = {
            left: (cornerPoints.tl.x + cornerPoints.bl.x) / 2,
            top: (cornerPoints.tl.y + cornerPoints.bl.y) / 2,
          };
          break;

        case TextVerticalAlignType.BOTTOM:
          params = {
            left: slideshowFabricObject.aCoords.bl.x + displacement * Math.sin(theta),
            top: slideshowFabricObject.aCoords.bl.y - displacement * Math.cos(theta),
          };
          break;

        default:
          params = {
            left: slideshowFabricObject.aCoords.tl.x + displacement * Math.cos(theta),
            top: slideshowFabricObject.aCoords.tl.y + displacement * Math.sin(theta),
          };
          console.error(`Unknown align type value '${this.verticalAlign}`);
      }
      params.originY = this.verticalAlign;
    }

    return params;
  }

  /**
   * Returns true if the slide is horizontally aligned currently.
   */
  public hasHorizontalAlignmentAxis(): boolean {
    // if (this.hasParentGroupGraphicItem()) {
    const slideshowFabricObject = this.slide.fabricObject.group as Fabric.Group;
    const scaleX = (slideshowFabricObject.width - getScaledManualHorizontalPadding(slideshowFabricObject.scaleX)) / this.slide.fabricObject.width;
    const scaleY = (slideshowFabricObject.height - getScaledManualVerticalPadding(slideshowFabricObject.scaleY)) / this.slide.fabricObject.height;

    return scaleX >= scaleY;
    // }
  }

  /**
   * Updates the scale of media slide. The aspect ratio has to be maintained for all media slides.
   */
  public updateMediaSlideScale(): void {
    // if (this.hasParentGroupGraphicItem()) {
    const slideshowFabricObject = this.slide.fabricObject.group as Fabric.Group;
    const scaleX = (slideshowFabricObject.width - getScaledManualHorizontalPadding(slideshowFabricObject.scaleX)) / this.slide.fabricObject.width;
    const scaleY = (slideshowFabricObject.height - getScaledManualVerticalPadding(slideshowFabricObject.scaleY)) / this.slide.fabricObject.height;
    const newScale = scaleX < scaleY ? scaleX : scaleY;

    this.slide.fabricObject.set({
      scaleY: newScale,
      scaleX: newScale,
    });
    this.slide.scaleX = newScale;
    this.slide.scaleY = newScale;
    // }
  }
}
