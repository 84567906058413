import {repoURL} from '@Libraries/s3-library';
import {getWriteBucket} from '@Utils/s3.util';
import {isWebpSupported} from '@Utils/browser.util';
import {loadImageAsync} from '@Utils/image.util';

export interface AnimatedSprite {
  id: number;
  hashedFilename: string;
  frameRate: number;
  frameWidth: number;
  numberOfSprites: number;
}

const ANIMATED_SPRITE_S3_DIR = 'animatedsprites';
const SPRITE_EXTENSION = 'webp';
const SPRITE_FALLBACK_EXTENSION = 'png';
const ANIMATED_SPRITE_UPDATED_ON = 1615206102;

let tempCanvasEl: HTMLCanvasElement | undefined;
const framesCache: Record<string, Array<HTMLImageElement>> = {};

export const repoStickerSpriteURL = (hashedFilename: string, spriteNumber: number): string => {
  return repoURL(`${ANIMATED_SPRITE_S3_DIR}/${hashedFilename}_${spriteNumber}.${getStickerSpriteExtension()}?ts=${ANIMATED_SPRITE_UPDATED_ON}`, getWriteBucket());
};

export const getFramesForSprite = async (sprite: AnimatedSprite): Promise<Array<HTMLImageElement>> => {
  if (framesCache[sprite.id]) {
    return framesCache[sprite.id];
  }

  const spriteImages = await loadSpriteImageFiles(sprite);
  if (!spriteImages[0]) {
    throw new Error(`No sprite image loaded for ${JSON.stringify(sprite)}`);
  }

  const frameImages: Array<HTMLImageElement> = [];
  const canvasElToGenerateFrames = getTempCanvasToGenerateFrames(sprite.frameWidth, spriteImages[0].height);

  for (const spriteImage of spriteImages) {
    frameImages.push(...getFrameImagesFromSpriteImage(canvasElToGenerateFrames, spriteImage, sprite.frameWidth));
  }

  framesCache[sprite.id] = frameImages;
  return frameImages;
};

export const loadSpriteImageFiles = (sprite: AnimatedSprite): Promise<Array<HTMLImageElement>> => {
  const imageLoadPromises = [];
  for (let i = 0; i < sprite.numberOfSprites; i++) {
    imageLoadPromises.push(loadImageAsync(repoStickerSpriteURL(sprite.hashedFilename, i)));
  }

  return Promise.all(imageLoadPromises);
};

const getTempCanvasToGenerateFrames = (frameWidth: number, frameHeight: number): HTMLCanvasElement => {
  tempCanvasEl = document.createElement('canvas');
  tempCanvasEl.width = frameWidth;
  tempCanvasEl.height = frameHeight;
  return tempCanvasEl;
};

const getFrameImagesFromSpriteImage = (canvasElToGenerateFrames: HTMLCanvasElement, spriteImage: HTMLImageElement, frameWidth: number): Array<HTMLImageElement> => {
  const frameImages: Array<HTMLImageElement> = [];

  const steps = spriteImage.width / frameWidth;
  for (let i = 0; i < steps; i++) {
    frameImages.push(getFrameImageFromSprite(canvasElToGenerateFrames, spriteImage, i, frameWidth));
  }
  return frameImages;
};

const getFrameImageFromSprite = (canvasElToGenerateFrames: HTMLCanvasElement, spriteImage: HTMLImageElement, frameNumber: number, frameWidth: number): HTMLImageElement => {
  const tmpCtx = canvasElToGenerateFrames.getContext('2d');
  if (!tmpCtx) {
    throw new Error(`Null canvas context when creating frames from sprite`);
  }
  tmpCtx.clearRect(0, 0, canvasElToGenerateFrames.width, canvasElToGenerateFrames.height);
  tmpCtx.drawImage(spriteImage, -frameNumber * frameWidth, 0);

  const dataURL = canvasElToGenerateFrames.toDataURL('image/png');
  const tmpImg = new Image();

  tmpImg.src = dataURL;
  return tmpImg;
};

const getStickerSpriteExtension = (): string => {
  return isWebpSupported() ? SPRITE_EXTENSION : SPRITE_FALLBACK_EXTENSION;
};
