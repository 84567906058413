import {Howl, Howler} from 'howler';
import {isAudioPlayable} from '@Utils/audio.util';
import {Observable} from '@PosterWhiteboard/observable';
import type {AudioPlayerObject, FadeData, TrimData} from '@Libraries/audio-player/audio-player.types';
import {AUDIO_PLAYER_EVENT} from '@Libraries/audio-player/audio-player.types';
import {floor} from 'lodash';
import type {DeepPartial} from '@/global';

Howler.autoSuspend = false;

export class AudioPlayer extends Observable {
  public trim: TrimData = {
    isTrimmed: false,
    startTime: 0,
    endTime: 0,
  };

  public fade: FadeData = {
    fadeInDuration: 0,
    fadeOutDuration: 0,
  };

  public audioUrl = '';
  public originalDuration = 0;
  public playCycles = 1;
  public speed = 1;
  public volume = 1;

  public currentLoop = 0;
  private howlObject!: Howl;
  private soundId?: number;
  private readonly updateVolumeInterval: NodeJS.Timeout;

  public constructor() {
    super();
    this.updateVolumeInterval = setInterval(() => {
      this.updateVolume();
      this.pauseAudioIfEnd();
    }, 100);
  }

  public getPlaybackCurrentTime(): number {
    return (this.getTrimmedCurrentTime() + this.currentLoop * this.getTrimmedDuration()) / this.speed;
  }

  private pauseAudioIfEnd(): void {
    if (this.getPlaybackCurrentTime() > this.getPlaybackDuration()) {
      this.pause();
      this.fire(AUDIO_PLAYER_EVENT.ON_END);
    }
  }

  private updateVolume(): void {
    if (this.soundId) {
      const volume = this.getVolumeForCurrentTime();
      this.howlObject.volume(volume, this.soundId);
    }
  }

  private getVolumeForCurrentTime = (): number => {
    const currentTime = this.getPlaybackCurrentTime();
    if (currentTime < this.fade.fadeInDuration) {
      return this.volume * (currentTime / this.fade.fadeInDuration);
    }

    const playbackDuration = this.getPlaybackDuration();
    if (currentTime > playbackDuration - this.fade.fadeOutDuration && currentTime < playbackDuration) {
      return this.volume * ((playbackDuration - currentTime) / this.fade.fadeOutDuration);
    }

    return this.volume;
  };

  public async updateFromObject(obj: DeepPartial<AudioPlayerObject>): Promise<void> {
    const oldObject = this.toObject();
    this.copyVals(obj);
    const reinit = this.hasDestructiveChange(oldObject);

    if (reinit) {
      await this.reinit();
      await this.ensureFadeIsInLimits();
    }

    this.invalidate();
  }

  public async ensureFadeIsInLimits(): Promise<void> {
    const maxFadeDuration = this.getMaxFadeDuration();
    if (this.fade.fadeInDuration > maxFadeDuration || this.fade.fadeOutDuration > maxFadeDuration) {
      this.fade = {
        fadeInDuration: Math.min(this.fade.fadeInDuration, maxFadeDuration),
        fadeOutDuration: Math.min(this.fade.fadeOutDuration, maxFadeDuration),
      };
    }
  }

  public copyVals(obj: DeepPartial<AudioPlayerObject>): void {
    if (obj.audioUrl !== undefined) {
      this.audioUrl = obj.audioUrl;
    }
    if (obj.originalDuration !== undefined) {
      this.originalDuration = obj.originalDuration;
    }
    if (obj.playCycles !== undefined) {
      this.playCycles = obj.playCycles;
    }
    if (obj.volume !== undefined) {
      this.volume = obj.volume;
    }
    if (obj.speed !== undefined) {
      this.speed = obj.speed;
    }
    if (obj.trim !== undefined) {
      this.trim = {
        ...this.trim,
        ...obj.trim,
      };
    }
    if (obj.fade !== undefined) {
      this.fade = {
        ...this.fade,
        ...obj.fade,
      };
    }
  }

  public toObject(): AudioPlayerObject {
    return {
      audioUrl: this.audioUrl,
      originalDuration: this.originalDuration,
      playCycles: this.playCycles,
      trim: this.trim,
      speed: this.speed,
      volume: this.volume,
      fade: this.fade,
    };
  }

  public getPlaybackDuration(): number {
    return this.playCycles * this.getTrimmedPlaybackDuration();
  }

  public unload(): void {
    clearInterval(this.updateVolumeInterval);
    this.pause();
    this.howlObject.unload();
  }

  public hasFade(): boolean {
    return this.fade.fadeInDuration !== 0 || this.fade.fadeOutDuration !== 0;
  }

  public async init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.howlObject = new Howl({
        html5: true, // true allows us to load audio partially
        src: [this.audioUrl],
        volume: this.getVolumeForCurrentTime(),
        rate: this.speed,
        sprite: {
          trimmed: [Math.floor(this.getTrimmedStartTime() * 1000), Math.floor(this.getTrimmedDuration() * 1000), false],
        },
        onload: (): void => {
          resolve();
        },
        onloaderror: (soundId, error): void => {
          reject(error);
        },
        onplay: (): void => {
          this.fire(AUDIO_PLAYER_EVENT.ON_PLAY);
        },
        onpause: (): void => {
          this.fire(AUDIO_PLAYER_EVENT.ON_PAUSE);
        },
        onstop: (): void => {
          this.fire(AUDIO_PLAYER_EVENT.ON_STOP);
        },
        onend: (): void => {
          void this.onEnd();
        },
      });
    });
  }

  private async onEnd(): Promise<void> {
    this.currentLoop += 1;
    if (this.shouldLoopAudio()) {
      await this.seekToPlaybackTime(this.getPlaybackCurrentTime());
      await this.play();
    }
  }

  private shouldLoopAudio(): boolean {
    return this.playCycles > this.currentLoop + 1;
  }

  public getTrimmedPlaybackDuration(): number {
    return this.getTrimmedDuration() / this.speed;
  }

  public getTrimmedDuration(): number {
    if (this.trim.isTrimmed) {
      return this.trim.endTime - this.trim.startTime;
    }

    return this.originalDuration;
  }

  public getMaxFadeDuration(): number {
    if (this.getPlaybackDuration() < 2) {
      return 0;
    }

    return floor(this.getPlaybackDuration() / 2, 2);
  }

  private async reinit(): Promise<void> {
    const oldHowlObject = this.howlObject;
    await this.init();
    oldHowlObject.unload();
    this.soundId = undefined;
  }

  public getTrimmedCurrentTime(): number {
    if (!this.soundId) {
      return 0;
    }

    const audioItemCurrentTime = this.howlObject.seek(this.soundId);
    if (typeof audioItemCurrentTime === 'object') {
      return 0;
    }
    return Math.max(0, audioItemCurrentTime - this.getTrimmedStartTime());
  }

  public getTrimmedStartTime(): number {
    return this.trim.isTrimmed ? this.trim.startTime : 0;
  }

  public getTrimmedEndTime(): number {
    return this.trim.isTrimmed ? this.trim.endTime : this.originalDuration;
  }

  public async seekToPlaybackTime(time: number): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.soundId) {
        resolve();
        return;
      }

      const playbackDuration = this.getPlaybackDuration();
      if (time > playbackDuration) {
        console.error(`Invalid bigger seek value: ${time} given then total playback duration: ${playbackDuration}`);
      }

      const trimmedPlaybackDuration = this.getTrimmedPlaybackDuration();
      const seekPlaybackTime = time % trimmedPlaybackDuration;
      const loop = Math.floor(time / trimmedPlaybackDuration);
      const seekTime = seekPlaybackTime * this.speed;
      const timeout = setTimeout(() => {
        // Seek timed out. Maybe the howl object was unloaded before its event could fire. Not throwing an error because of this
        resolve();
      }, 1000);

      this.howlObject.once(
        'seek',
        () => {
          this.currentLoop = loop;
          clearInterval(timeout);
          resolve();
        },
        this.soundId
      );

      this.howlObject.seek(seekTime + this.getTrimmedStartTime(), this.soundId);
    });
  }

  public pause(): void {
    this.howlObject.pause(this.soundId);
  }

  public async play(): Promise<void> {
    if (!this.isPlaying() && isAudioPlayable()) {
      if (this.soundId) {
        this.howlObject.play(this.soundId);
      } else {
        this.soundId = this.howlObject.play('trimmed');
      }
    }

    if (!isAudioPlayable()) {
      throw new Error("Audio can't be played right now, need user gesture");
    }
  }

  public isPlaying(): boolean {
    return this.howlObject.playing(this.soundId);
  }

  private invalidate(): void {
    this.updateVolume();
    if (this.soundId) {
      this.howlObject.rate(this.speed, this.soundId);
    }
  }

  private hasDestructiveChange(oldAudioPlayerObject: AudioPlayerObject): boolean {
    return oldAudioPlayerObject.trim.startTime !== this.trim.startTime || oldAudioPlayerObject.trim.endTime !== this.trim.endTime;
  }
}
