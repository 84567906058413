import {CommonMethods} from '@PosterWhiteboard/common-methods';
import {AnimationType, getEaseFunctionForAnimationType, isWipeOrPeekAnimation, PositionType, SlideType} from '@PosterWhiteboard/animation/animation.class';
import {getScaledManualHorizontalPadding} from '@PosterWhiteboard/items/item/item.library';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import type {FabricObject, Group, Path} from '@postermywall/fabricjs-2';
import {Rect, util} from '@postermywall/fabricjs-2';

export interface AnimateItemConfig {
  duration?: number;

  onComplete?: () => void;

  delay?: number;
  direction?: SlideType | PositionType;
}

export enum AnimateItemType {
  INTRO_ANIMATE_ITEM = 'intro-animate-item',
  OUTRO_ANIMATE_ITEM = 'outro-animate-item',
}

const DEFAULT_DURATION = 1;
const NUMBER_THOUSAND = 1000;
const ROTATE_ANIMATION_ANGLE = 135;
const MAX_LARGER_DIMENSION_SKEW_VALUE = 40;
const MAX_SMALLER_DIMENSION_SKEW_VALUE = 15;

export abstract class AnimateItem extends CommonMethods {
  public abstract type: AnimateItemType;
  private animationType: AnimationType = AnimationType.NONE;
  private callback: () => void = () => {};
  private delay = 0;
  private direction: SlideType | PositionType = SlideType.LEFT;
  private duration = DEFAULT_DURATION;
  private onAnimationChange: () => void = () => {};
  private canvas;
  private clippedItem!: ItemFabricObject;
  private isAnimationOver = false;
  private initSetFrame = false;
  private isFinalFrameSet = false;
  protected fabricObject!: ItemFabricObject;
  protected animatingItem!: ItemFabricObject;
  protected viewComponentOpacitySnapshot = 0;
  protected clipPath!: Rect;
  protected block!: Rect;
  protected onStartValues = {};
  protected onCompleteValues: Record<string, any> = {};
  protected startingAnimationValues: Record<string, any> = {};
  protected endingAnimationValues: Record<string, any> = {};
  protected defaultValues: Record<string, any> = {};
  public item!: ItemType;
  public internalOnComplete: () => void = () => {};

  constructor(item: ItemType, animationType: AnimationType, opts: AnimateItemConfig) {
    super();
    this.item = item;
    this.animationType = animationType;
    this.callback = opts.onComplete ? opts.onComplete : this.callback;
    this.delay = opts.delay ? opts.delay : this.delay;
    this.direction = opts.direction ? opts.direction : this.direction;
    this.duration = opts.duration ? opts.duration : this.duration;
    this.fabricObject = item.fabricObject;
    this.canvas = item.page.fabricCanvas;
  }

  public start(): void {
    const {left, top} = this.fabricObject;

    if (this.animationType === AnimationType.BLOCK) {
      this.initBlockAnimationItems();
      this.fabricObject.dirty = true;

      this.setInternalOnCompleteByAnimationType();
      this.startAnimation();
    } else if (isWipeOrPeekAnimation(this.animationType)) {
      this.setClippedItem();
      this.initClipPathOnObjectAnimation();

      this.setInternalOnCompleteByAnimationType();
      this.startAnimation();
    } else if (!this.item.page.poster.isHighRes && this.isComplex()) {
      const {opacity} = this.fabricObject;

      this.fabricObject.set({angle: 0});
      const clone = this.fabricObject.cloneAsImage({});
      this.fabricObject.set('opacity', 0);
      this.fabricObject.set({angle: this.item?.rotation});
      this.fabricObject.setCoords();

      clone.set({top, left, angle: this.item?.rotation, selectable: false});
      this.canvas.add(clone);
      this.animatingItem = clone;
      this.canvas.moveObjectTo(this.animatingItem as FabricObject, this.canvas.getObjects().indexOf(this.fabricObject as FabricObject));
      this.internalOnComplete = (): void => {
        this.canvas.remove(clone);
        this.fabricObject.set('opacity', opacity);
      };
      this.startAnimation();
    } else {
      this.animatingItem = this.fabricObject;
      this.startAnimation();
    }
  }

  public startAnimation(): void {
    this.initAnimation();

    setTimeout(() => {
      let i = 0;
      this.animatingItem.animate(this.endingAnimationValues, {
        duration: this.duration * NUMBER_THOUSAND,
        easing: getEaseFunctionForAnimationType(this.animationType),
        onComplete: () => {
          i += 1;
          if (i === Object.keys(this.endingAnimationValues).length) {
            this.onAnimationComplete();
          }
        },
        onChange: this.onAnimationChange.bind(this),
        abort: () => {
          return this.isAnimationOver;
        },
      });
    }, this.getDelayInMilliSeconds(this.delay));
  }

  public getDelayInMilliSeconds(delayInSeconds: number): number {
    return delayInSeconds * NUMBER_THOUSAND;
  }

  protected onAnimationComplete(): void {
    this.fabricObject.set(this.onCompleteValues);
    if (this.internalOnComplete) {
      this.internalOnComplete();
    }
    this.callback();
  }

  public initAnimation(): void {}

  public setInternalOnCompleteByAnimationType(): void {
    switch (this.animationType) {
      case AnimationType.BLOCK:
        this.internalOnComplete = (): void => {
          this.restoreOpacityOnComplete();
          this.fabricObject.dirty = true;
          this.canvas.remove(this.block);
        };
        break;

      case AnimationType.WIPE_DOWN:
      case AnimationType.WIPE_UP:
      case AnimationType.WIPE_LEFT:
      case AnimationType.WIPE_RIGHT:
      case AnimationType.PEEK_DOWN:
      case AnimationType.PEEK_UP:
      case AnimationType.PEEK_LEFT:
      case AnimationType.PEEK_RIGHT:
        this.internalOnComplete = (): void => {
          if (this.item?.isTextSlide()) {
            this.removeClipPathFromText();
          } else {
            this.clippedItem.set({clipPath: null});

            if (this.clippedItem !== this.fabricObject) {
              this.canvas.remove(this.clippedItem);
              this.fabricObject.set({opacity: this.viewComponentOpacitySnapshot});
            }
          }

          this.hideItemOnComplete();
        };
        break;

      default:
        break;
    }
  }

  public isComplex(): boolean {
    if (this.fabricObject.get('type') === 'group') {
      return !this.item?.isSlideshow() && !this.item.isText() && !this.item.isTextSlide();
    }

    if (this.fabricObject.get('type') === 'path') {
      // TODO: Remove this when we upgrade to Fabric v6.
      const fabricObject = this.fabricObject as Path;
      return fabricObject.path.length > 20;
    }

    return false;
  }

  public stop(): void {
    this.isAnimationOver = true;

    this.animatingItem.set(this.endingAnimationValues);
    this.animatingItem.set(this.onCompleteValues);
    this.onAnimationComplete();
  }

  public getAnimationValues(): Record<string, any> {
    let bound = null;
    let animationValues: Record<string, any> = {};
    let initialLeftValue;
    let theta;
    const poster = window.posterEditor?.whiteboard;

    if (poster) {
      switch (this.animationType) {
        case AnimationType.SLIDE:
          bound = this.animatingItem.getBoundingRect();
          initialLeftValue =
            this.direction === SlideType.LEFT ? this.canvas.width / poster.scaling.scale - bound.left + this.item.x - 10 : this.item.x - (bound.left + bound.width) - 10;

          animationValues = {
            opacity: 0,
            left: initialLeftValue,
          };
          break;

        case AnimationType.FADE:
          animationValues = {
            opacity: 0,
            scaleX: this.animatingItem.scaleX * 0.95,
            scaleY: this.animatingItem.scaleY * 0.95,
          };
          break;

        case AnimationType.POP:
          animationValues = {
            opacity: 0,
            scaleX: this.animatingItem.scaleX / 4,
            scaleY: this.animatingItem.scaleY / 4,
          };
          break;

        case AnimationType.JELLO:
          animationValues = {
            opacity: 0,
            skewX:
              this.animatingItem.width > this.animatingItem.height
                ? MAX_LARGER_DIMENSION_SKEW_VALUE
                : Math.max(MAX_SMALLER_DIMENSION_SKEW_VALUE, (this.animatingItem.width / this.animatingItem.height) * MAX_LARGER_DIMENSION_SKEW_VALUE),
            skewY:
              this.animatingItem.width > this.animatingItem.height
                ? Math.max(MAX_SMALLER_DIMENSION_SKEW_VALUE, (this.animatingItem.height / this.animatingItem.width) * MAX_LARGER_DIMENSION_SKEW_VALUE)
                : MAX_LARGER_DIMENSION_SKEW_VALUE,
          };

          break;

        case AnimationType.ROTATE: {
          const initialAngle = this.direction === SlideType.LEFT ? -ROTATE_ANIMATION_ANGLE : ROTATE_ANIMATION_ANGLE;
          animationValues = {
            opacity: 0,
            scaleX: this.animatingItem.scaleX / 2,
            scaleY: this.animatingItem.scaleY / 2,
            angle: initialAngle,
          };
          break;
        }

        case AnimationType.TUMBLE:
          bound = this.animatingItem.getBoundingRect();
          animationValues = {
            opacity: 0,
          };

          switch (this.direction) {
            case PositionType.TOP:
              animationValues.top = this.item.y - (bound.top + bound.height) - 10;
              break;

            case PositionType.BOTTOM:
              animationValues.top = this.canvas.height / poster.scaling.scale - bound.top + this.item.y - 10;
              break;

            case PositionType.LEFT:
              animationValues.left = -bound.height - 10;
              animationValues.angle = this.animatingItem.angle - 90;
              break;

            case PositionType.RIGHT:
              animationValues.left = this.canvas.width / poster.scaling.scale + bound.width + 10;
              animationValues.angle = this.animatingItem.angle + 180;
              break;

            case PositionType.TOP_LEFT:
              animationValues.left = -bound.width - 10;
              animationValues.top = -bound.height - 10;
              animationValues.angle = this.animatingItem.angle - 180;
              break;

            case PositionType.BOTTOM_LEFT:
              animationValues.left = -bound.width - 10;
              animationValues.top = this.canvas.height / poster.scaling.scale + bound.height + 10;
              animationValues.angle = this.animatingItem.angle + 180;
              break;

            case PositionType.TOP_RIGHT:
              animationValues.left = this.canvas.width / poster.scaling.scale + bound.width + 10;
              animationValues.top = -bound.height - 10;
              animationValues.angle = this.animatingItem.angle - 180;
              break;

            default: // case PositionType.BOTTOM_RIGHT:
              animationValues.left = this.canvas.width / poster.scaling.scale + bound.width + 10;
              animationValues.top = this.canvas.height / poster.scaling.scale + bound.height + 10;
              animationValues.angle = this.animatingItem.angle + 180;
              break;
          }
          break;

        case AnimationType.SHRINK:
          animationValues = {
            opacity: 0,
            scaleX: this.animatingItem.scaleX * 2,
            scaleY: this.animatingItem.scaleY * 2,
          };
          break;

        case AnimationType.EXPAND:
          animationValues = {
            opacity: 0,
            scaleX: this.animatingItem.scaleX / 2,
            scaleY: this.animatingItem.scaleY / 2,
          };
          break;

        case AnimationType.PAN_RIGHT:
          animationValues = {
            opacity: 0,
            left: this.fabricObject.left - 0.2 * poster.width,
          };
          break;

        case AnimationType.PAN_LEFT:
          animationValues = {
            opacity: 0,
            left: this.fabricObject.left + 0.2 * poster.width,
          };
          break;

        case AnimationType.PAN_DOWN:
          animationValues = {
            opacity: 0,
            top: this.fabricObject.top - 0.2 * poster.height,
          };
          break;

        case AnimationType.PAN_UP:
          animationValues = {
            opacity: 0,
            top: this.fabricObject.top + 0.2 * poster.height,
          };
          break;

        case AnimationType.RISE:
          animationValues = {
            opacity: 0,
            top: this.item.y + 70,
          };
          break;

        case AnimationType.PAN: {
          const displacement = 0.2 * poster.width;

          initialLeftValue = this.direction === SlideType.LEFT ? this.item.x + displacement : this.item.x - displacement;
          animationValues = {
            opacity: 0,
            left: initialLeftValue,
          };
          break;
        }

        case AnimationType.BOUNCE_IN_DOWN:
          bound = this.animatingItem.getBoundingRect();
          animationValues = {
            opacity: 0,
            top: this.item.y - (bound.top + bound.height) - 10,
          };
          break;

        case AnimationType.BLOCK:
          animationValues = this.getAnimationBlockCoordinates();
          break;

        case AnimationType.WIPE_DOWN:
          theta = util.degreesToRadians(this.animatingItem.angle);
          animationValues = {
            left: this.animatingItem.left + this.animatingItem.getScaledHeight() * Math.sin(theta),
            top: this.animatingItem.top - this.animatingItem.getScaledHeight() * Math.cos(theta),
          };
          break;

        case AnimationType.WIPE_UP:
          theta = util.degreesToRadians(this.animatingItem.angle);
          animationValues = {
            left: this.animatingItem.left - this.animatingItem.getScaledHeight() * Math.sin(theta),
            top: this.animatingItem.top + this.animatingItem.getScaledHeight() * Math.cos(theta),
          };
          break;

        case AnimationType.WIPE_LEFT:
          theta = util.degreesToRadians(this.animatingItem.angle);
          animationValues = {
            left: this.animatingItem.left + this.animatingItem.getScaledWidth() * Math.cos(theta),
            top: this.animatingItem.top + this.animatingItem.getScaledWidth() * Math.sin(theta),
          };
          break;

        case AnimationType.WIPE_RIGHT:
          theta = util.degreesToRadians(this.animatingItem.angle);
          animationValues = {
            left: this.animatingItem.left - this.animatingItem.getScaledWidth() * Math.cos(theta),
            top: this.animatingItem.top - this.animatingItem.getScaledWidth() * Math.sin(theta),
          };
          break;

        case AnimationType.PEEK_DOWN:
          if (this.clippedItem === this.fabricObject) {
            animationValues = {
              top: this.animatingItem.top - this.clipPath.height,
            };
          }
          // the case where masked image is cloned as image to be clipped
          else {
            theta = util.degreesToRadians(this.animatingItem.angle);
            animationValues = {
              left: this.animatingItem.left + this.clipPath.height * (this.fabricObject?.group?.scaleY ?? 1) * Math.sin(theta),
              top: this.animatingItem.top - this.clipPath.height * (this.fabricObject?.group?.scaleY ?? 1) * Math.cos(theta),
            };
          }
          break;

        case AnimationType.PEEK_UP:
          if (this.clippedItem === this.fabricObject) {
            animationValues = {
              top: this.animatingItem.top + this.clipPath.height,
            };
          }
          // the case where masked image is cloned as image to be clipped
          else {
            theta = util.degreesToRadians(this.animatingItem.angle);
            animationValues = {
              left: this.animatingItem.left - this.clipPath.height * (this.fabricObject?.group?.scaleY ?? 1) * Math.sin(theta),
              top: this.animatingItem.top + this.clipPath.height * (this.fabricObject?.group?.scaleY ?? 1) * Math.cos(theta),
            };
          }
          break;

        case AnimationType.PEEK_LEFT:
          if (this.clippedItem === this.fabricObject) {
            animationValues = {
              left: this.animatingItem.left + this.clipPath.width,
            };
          }
          // the case where masked image is cloned as image to be clipped
          else {
            theta = util.degreesToRadians(this.animatingItem.angle);
            animationValues = {
              left: this.animatingItem.left + this.clipPath.width * (this.fabricObject?.group?.scaleX ?? 1) * Math.cos(theta),
              top: this.animatingItem.top + this.clipPath.width * (this.fabricObject?.group?.scaleX ?? 1) * Math.sin(theta),
            };
          }
          break;

        case AnimationType.PEEK_RIGHT:
          if (this.clippedItem === this.fabricObject) {
            animationValues = {
              left: this.animatingItem.left - this.clipPath.width,
            };
          }
          // the case where masked image is cloned as image to be clipped
          else {
            theta = util.degreesToRadians(this.animatingItem.angle);
            animationValues = {
              left: this.animatingItem.left - this.clipPath.width * (this.fabricObject?.group?.scaleX ?? 1) * Math.cos(theta),
              top: this.animatingItem.top - this.clipPath.width * (this.fabricObject?.group?.scaleX ?? 1) * Math.sin(theta),
            };
          }
          break;

        case AnimationType.NONE:
          break;

        default:
          console.error('Unknown animation type: ', this.animationType);
          break;
      }
    }

    this.setDefaultValues(Object.keys(animationValues));
    return animationValues;
  }

  public setDefaultValues(keys: Array<string>): void {
    this.defaultValues = {};
    for (let i = 0; i < keys.length; i++) {
      this.defaultValues[keys[i]] = this.animatingItem.get(keys[i]);
    }
  }

  public getOnStartValues(): Record<string, any> {
    let startingValues: Record<string, any> = {};

    switch (this.animationType) {
      case AnimationType.FADE:
      case AnimationType.POP:
      case AnimationType.JELLO:
      case AnimationType.ROTATE:
      case AnimationType.SHRINK:
      case AnimationType.EXPAND:
        startingValues = {
          left: this.animatingItem.getRelativeCenterPoint().x,
          top: this.animatingItem.getRelativeCenterPoint().y,
          originX: 'center',
          originY: 'center',
          scaleX: this.animatingItem.scaleX,
          scaleY: this.animatingItem.scaleY,
        };
        break;

      case AnimationType.PEEK_DOWN:
      case AnimationType.PEEK_UP:
      case AnimationType.PAN_DOWN:
      case AnimationType.PAN_UP:
        startingValues = {
          top: this.animatingItem.top,
        };
        break;

      case AnimationType.PEEK_LEFT:
      case AnimationType.PEEK_RIGHT:
      case AnimationType.PAN_LEFT:
      case AnimationType.PAN_RIGHT:
        startingValues = {
          left: this.animatingItem.left,
        };
        break;

      default:
        break;
    }

    this.setOnCompleteValues(Object.keys(startingValues));
    return startingValues;
  }

  public setOnCompleteValues(keys: Array<string>): void {
    this.onCompleteValues = {};
    for (let i = 0; i < keys.length; i++) {
      this.onCompleteValues[keys[i]] = this.fabricObject.get(keys[i]);
    }
  }

  public getAnimationBlockCoordinates(): Record<string, any> {
    const theta = util.degreesToRadians(this.clipPath.angle);
    // we add extra displacement for block animation because if not, its pixel remains even after the block escapes
    const extraDisplacement = 3;

    return {
      left: this.clipPath.left - (extraDisplacement + this.clipPath.getScaledWidth()) * Math.cos(theta),
      top: this.clipPath.top - (extraDisplacement + this.clipPath.getScaledWidth()) * Math.sin(theta),
    };
  }

  public initClipPathOnObjectAnimation(): void {
    this.fabricObject.setCoords();

    switch (this.animationType) {
      case AnimationType.WIPE_DOWN:
      case AnimationType.WIPE_UP:
      case AnimationType.WIPE_LEFT:
      case AnimationType.WIPE_RIGHT:
        this.createPaddedObjectSizeClipPath();
        this.animatingItem = this.clipPath;
        break;

      case AnimationType.PEEK_DOWN:
      case AnimationType.PEEK_UP:
      case AnimationType.PEEK_LEFT:
      case AnimationType.PEEK_RIGHT:
        this.createSelectorSizeClipPath();
        this.animatingItem = this.clippedItem;
        break;

      default:
        break;
    }

    if (this.item?.isTextSlide()) {
      this.setClipPathOnText();
    } else {
      this.clippedItem.set({clipPath: this.clipPath});
    }
  }

  public setClipPathOnText(): void {
    if (this.item?.aura.isShadow()) {
      // TODO: Remove this when we upgrade to Fabric v6.
      const textFabricObject = this.fabricObject as Group;
      const groupObjects = textFabricObject.getObjects();
      for (let i = 0; i < groupObjects.length; i++) {
        groupObjects[i].set({clipPath: this.clipPath});
      }
    } else {
      this.fabricObject.set({clipPath: this.clipPath});
    }
  }

  public createPaddedObjectSizeClipPath(): void {
    if (this.item.isText() || this.item.isTextSlide()) {
      if (this.item.hasBackground()) {
        this.createSelectorSizeClipPath();
      } else {
        this.initClipPathProperties();

        this.clipPath.width = this.item.fabricTextbox.width + getScaledManualHorizontalPadding(this.clipPath.scaleX) + this.item.getBulletsWidth();
        this.clipPath.height = this.item.fabricTextbox.height + getScaledManualHorizontalPadding(this.clipPath.scaleY);

        this.clipPath.set(this.item.getPaddedTextChildAbsolutePosition(this.clipPath));
        this.clipPath.setCoords();
      }
    } else {
      this.createClipPathForMediaSlides();
    }
  }

  public setClippedItem(): void {
    if (this.item.isImageSlide() && (this.item.isMasked() || this.item.effects.doesEdgeEffectNeedClipping())) {
      const imageSlideItem = this.item;
      this.viewComponentOpacitySnapshot = this.fabricObject.opacity;

      const clone = this.fabricObject.cloneAsImage({});
      if (this.fabricObject.group) {
        this.fabricObject.set('opacity', 0);

        clone.set({
          angle: this.fabricObject.group.angle,
          scaleX: this.fabricObject.group.scaleX,
          scaleY: this.fabricObject.group.scaleY,
          selectable: false,
        });

        clone.set(imageSlideItem.mediaSlide.getMediaSlideAbsolutePosition());
        clone.setCoords();

        this.canvas.add(clone);

        this.clippedItem = clone;
        this.canvas.moveObjectTo(this.clippedItem, this.canvas.getObjects().indexOf(this.fabricObject.group) + 1);
      }
    } else {
      this.clippedItem = this.fabricObject;
    }
  }

  public removeClipPathFromText(): void {
    if (this.item?.aura.isShadow()) {
      // TODO: Remove this when we upgrade to Fabric v6.
      const textFabricObject = this.fabricObject as Group;
      const groupObjects = textFabricObject.getObjects();
      for (let i = 0; i < groupObjects.length; i++) {
        groupObjects[i].set({clipPath: null});
      }
    } else {
      this.fabricObject.set({clipPath: null});
    }
  }

  public initBlockAnimationItems(): void {
    this.viewComponentOpacitySnapshot = this.fabricObject.opacity;
    this.onAnimationChange = this.blockAnimationOnChange.bind(this);

    // const isItemText = this.item?.isText() || this.item?.isTextSlide();
    // const isItemText = this.item instanceof TextItem;
    // const isTextEmpty = isItemText && this.item.isTextEmpty();

    const textItem = this.item.isText() || this.item.isTextSlide() ? this.item : undefined;
    const isItemText = !!textItem;
    const isTextEmpty = textItem && textItem.isTextEmpty();
    const fill = this.item.isText() || this.item.isTextSlide() || this.item.isImageSlide() || this.item.isVideoSlide() ? this.item?.getFill() : '';

    this.createClipPathForBlock();

    this.block = new Rect({
      angle: this.clipPath.angle,
      width: isItemText && isTextEmpty ? 0 : this.clipPath.width,
      height: this.clipPath.height,
      scaleX: this.clipPath.scaleX,
      scaleY: this.clipPath.scaleY,
      fill,
      clipPath: this.clipPath,
      selectable: false,
    });
    this.setInitalBlockPositionParams();

    this.canvas.add(this.block);

    this.hideItemOnBlockInit();
    this.animatingItem = this.block;
    const isViewComponentGroupObject = !!this.fabricObject.group;
    let itemToMove;

    if (isViewComponentGroupObject) {
      itemToMove = this.fabricObject.group;
    } else {
      itemToMove = this.fabricObject;
    }

    if (itemToMove) {
      this.canvas.moveObjectTo(this.animatingItem, this.canvas.getObjects().indexOf(itemToMove) + 1);
    }
  }

  public blockAnimationOnChange(): void {}

  public createClipPathForBlock(): void {
    if (this.item.isText() || this.item.isTextSlide()) {
      if (this.item.hasBackground()) {
        this.createSelectorSizeClipPath();
      } else {
        this.initClipPathProperties();

        this.clipPath.width = this.item.fabricTextbox.width + this.item.getBulletsWidth();
        this.clipPath.height = this.item.fabricTextbox.height;

        this.clipPath.set(this.item.getTextChildAbsolutePosition(this.clipPath));
        this.clipPath.setCoords();
      }
    } else if (this.item?.isMediaSlide()) {
      this.createClipPathForMediaSlides();
    }
  }

  public createSelectorSizeClipPath(): void {
    const isViewComponentGroupObject = !!this.fabricObject.group;

    this.initClipPathProperties();

    let fabricObject;

    if (isViewComponentGroupObject) {
      fabricObject = this.fabricObject.group;
    } else {
      fabricObject = this.fabricObject;
    }

    if (fabricObject) {
      // using aCoords because left/top values of fabricObject might be affected due to introAnimation.
      this.clipPath.left = isViewComponentGroupObject ? fabricObject.aCoords.tl.x : fabricObject.aCoords.tl.x;
      this.clipPath.top = isViewComponentGroupObject ? fabricObject.aCoords.tl.y : fabricObject.aCoords.tl.y;
      this.clipPath.width = isViewComponentGroupObject ? fabricObject.width : fabricObject.width;
      this.clipPath.height = isViewComponentGroupObject ? fabricObject.height : fabricObject.height;

      this.clipPath.setCoords();
    }
  }

  public initClipPathProperties(): void {
    const isViewComponentGroupObject = !!this.fabricObject.group;

    let fabricObject;

    if (isViewComponentGroupObject) {
      fabricObject = this.fabricObject.group;
    } else {
      fabricObject = this.fabricObject;
    }

    if (fabricObject) {
      this.clipPath = new Rect({
        angle: isViewComponentGroupObject ? fabricObject.angle : fabricObject.angle,
        scaleX: isViewComponentGroupObject ? fabricObject.scaleX : fabricObject.scaleX,
        scaleY: isViewComponentGroupObject ? fabricObject.scaleY : fabricObject.scaleY,
        absolutePositioned: true,
      });
    }
  }

  public createClipPathForMediaSlides(): void {
    this.initClipPathProperties();

    const mediaSlideItem = this.item as ImageSlideItem | VideoSlideItem;
    this.clipPath.width = this.item?.fabricObject.getScaledWidth();
    this.clipPath.height = this.item?.fabricObject.getScaledHeight();

    this.clipPath.set(mediaSlideItem.mediaSlide.getMediaSlideAbsolutePosition());
    this.clipPath.setCoords();
  }

  public setInitalBlockPositionParams(): void {
    if (this.item.isText() || this.item.isTextSlide()) {
      this.setTextItemBlockOrigin();
    } else if (this.item.isMediaSlide()) {
      this.setMediaItemBlockOrigin();
    }

    const theta = util.degreesToRadians(this.clipPath.angle);
    // we add extra displacement for block animation because if not, its pixel remains even after the block escapes
    const extraDisplacement = 3;

    this.block.set({
      left: this.clipPath.left + (extraDisplacement + this.clipPath.getScaledWidth()) * Math.cos(theta),
      top: this.clipPath.top + (extraDisplacement + this.clipPath.getScaledWidth()) * Math.sin(theta),
    });
  }

  public setTextItemBlockOrigin(): void {
    if ((this.item.isText() || this.item.isTextSlide()) && !this.item.hasBackground()) {
      this.block.originY = this.item.verticalAlign;
    }
  }

  public setMediaItemBlockOrigin(): void {
    if (this.item.isImageSlide() || this.item.isVideoSlide()) {
      if (this.item.mediaSlide.hasHorizontalAlignmentAxis()) {
        this.block.originX = this.item.mediaSlide.horizontalAlign;
      } else {
        this.block.originY = this.item.mediaSlide.verticalAlign;
      }
    }
  }

  public setFrame(time: number): void {
    if (!this.initSetFrame) {
      this.initSetFrame = true;

      switch (this.animationType) {
        case AnimationType.WIPE_DOWN:
        case AnimationType.WIPE_UP:
        case AnimationType.WIPE_LEFT:
        case AnimationType.WIPE_RIGHT:
        case AnimationType.PEEK_DOWN:
        case AnimationType.PEEK_UP:
        case AnimationType.PEEK_LEFT:
        case AnimationType.PEEK_RIGHT:
          this.setClippedItem();
          this.initClipPathOnObjectAnimation();

          this.internalOnComplete = (): void => {
            if (this.clippedItem !== this.fabricObject) {
              this.canvas.remove(this.clippedItem);
              this.fabricObject.set({opacity: this.viewComponentOpacitySnapshot});
            }

            this.hideItemOnComplete();
          };
          break;

        case AnimationType.BLOCK:
          this.initBlockAnimationItems();
          this.internalOnComplete = (): void => {
            this.canvas.remove(this.block);
          };
          break;

        default:
          this.animatingItem = this.fabricObject;
          break;
      }

      this.initAnimation();
    }

    const easeFunction = getEaseFunctionForAnimationType(this.animationType);
    const vals: Record<string, any> = {};

    const keys = Object.keys(this.startingAnimationValues);
    keys.forEach((key) => {
      vals[key] = easeFunction(
        Math.min(Math.max(time - this.delay, 0), this.duration),
        this.startingAnimationValues[key],
        this.endingAnimationValues[key] - this.startingAnimationValues[key],
        this.duration
      );
    });

    if (isWipeOrPeekAnimation(this.animationType) && this.item.isImageSlide() && (this.item.isMasked() || this.item.effects.doesEdgeEffectNeedClipping())) {
      this.fabricObject.set('opacity', 0);
    }

    this.animatingItem.set(vals);
    this.onAnimationChange();
    if (!this.isFinalFrameSet && time >= this.duration + this.delay) {
      this.fabricObject.set(this.onCompleteValues);
      if (this.internalOnComplete) {
        this.internalOnComplete();
      }
      this.isFinalFrameSet = true;
    }
  }

  public shouldBlockBeMadeVisible(): boolean {
    return (
      Math.abs(Math.round(this.block.left - this.startingAnimationValues.left)) - Math.abs(Math.round(this.clipPath.left - this.startingAnimationValues.left)) >= 0 &&
      Math.abs(Math.round(this.block.top - this.startingAnimationValues.top)) - Math.abs(Math.round(this.clipPath.top - this.startingAnimationValues.top)) >= 0
    );
  }

  public restoreOpacityOnComplete(): void {}

  public hideItemOnComplete(): void {}

  public hideItemOnBlockInit(): void {}
}

export const getOutroForIntroAnimation = (animationType: AnimationType): AnimationType => {
  switch (animationType) {
    case AnimationType.WIPE_DOWN:
      return AnimationType.WIPE_UP;

    case AnimationType.WIPE_UP:
      return AnimationType.WIPE_DOWN;

    case AnimationType.WIPE_LEFT:
      return AnimationType.WIPE_RIGHT;

    case AnimationType.WIPE_RIGHT:
      return AnimationType.WIPE_LEFT;

    case AnimationType.PEEK_DOWN:
      return AnimationType.PEEK_UP;

    case AnimationType.PEEK_UP:
      return AnimationType.PEEK_DOWN;

    case AnimationType.PEEK_LEFT:
      return AnimationType.PEEK_RIGHT;

    case AnimationType.PEEK_RIGHT:
      return AnimationType.PEEK_LEFT;

    case AnimationType.SHRINK:
      return AnimationType.EXPAND;

    case AnimationType.PAN_RIGHT:
      return AnimationType.PAN_LEFT;

    case AnimationType.PAN_LEFT:
      return AnimationType.PAN_RIGHT;

    case AnimationType.PAN_DOWN:
      return AnimationType.PAN_UP;

    case AnimationType.PAN_UP:
      return AnimationType.PAN_DOWN;

    default:
      return animationType;
  }
};
