import {CommonMethods} from '@PosterWhiteboard/common-methods';

/**
 * Max time difference in seconds that the audio and video can be out of sync
 */
const MAXIMUM_SYNC_THRESHOLD = 0.5;

interface SyncToPosterClockProps {
  getDuration(): number;

  getCurrentTime(): number;

  seek(time: number): Promise<void>;

  pauseAtEnd: boolean;
}

export class SyncToPosterClock extends CommonMethods {
  private syncing = false;
  private enable = true;
  private props: SyncToPosterClockProps;

  constructor(props: SyncToPosterClockProps) {
    super();
    this.props = props;
  }

  public disable(): void {
    this.enable = false;
  }

  public async syncToPage(time: number): Promise<void> {
    if (this.syncing || !this.enable) {
      return;
    }

    this.syncing = true;
    try {
      await this.syncItemToTime(time);
    } finally {
      this.syncing = false;
    }
  }

  private async syncItemToTime(time: number): Promise<void> {
    const relativeReferenceTime = this.props.pauseAtEnd ? time : time % this.props.getDuration();
    const currentTime = this.props.getCurrentTime();

    if (Math.abs(relativeReferenceTime - currentTime) > MAXIMUM_SYNC_THRESHOLD) {
      await this.props.seek(relativeReferenceTime);
    }
  }
}
