import type * as Fabric from '@postermywall/fabricjs-2';
import {updateTextSelectionPopup, updateTextSelectionPopUpOpenState} from '@Components/poster-editor/components/text-item-popup/text-item-popup-slice';
import {isMobile} from 'react-device-detect';

export const handleTextSelectionPopupMenu = (textItem: Fabric.Textbox | null): void => {
  if (!textItem) {
    return;
  }
  const start = textItem.selectionStart;
  const end = textItem.selectionEnd;

  window.PMW.redux.store.dispatch(
    updateTextSelectionPopup({
      target: textItem,
      wordPosition: {start, end},
      openTextSelectionPopupMenu: true,
    })
  );
};

export const updateTextSelection = (textItem: Fabric.Textbox | null): void => {
  if (!textItem) {
    return;
  }
  const isCursorOnText =
    textItem.text[textItem.selectionStart] !== '\n' &&
    textItem.text[textItem.selectionStart] !== ' ' &&
    textItem.text[textItem.selectionStart - 1] !== ' ' &&
    textItem.text[textItem.selectionStart] !== undefined;
  if (isCursorOnText) {
    const selectionStart = textItem.searchWordBoundary(textItem.selectionStart, -1);
    const selectionEnd = textItem.searchWordBoundary(textItem.selectionStart > 0 ? textItem.selectionStart : 1, 1);
    textItem.setSelectionStart(selectionStart);
    textItem.setSelectionEnd(selectionEnd);
  }
};

export const shouldShowTextSelectionPopup = (textItem: Fabric.Textbox | null): boolean => {
  return !!(isMobile && textItem && textItem.selectionStart !== textItem.selectionEnd);
};

export const closeTextSelectionPopup = (): void => {
  window.PMW.redux.store.dispatch(updateTextSelectionPopUpOpenState(false));
};
