export class Deferred {
  public promise: Promise<unknown>;
  public reject!: (reason?: any) => void;
  public resolve!: (value?: unknown) => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
