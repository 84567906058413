import type {ObjectEvents, SerializedImageProps, TClassProperties, TOptions} from '@postermywall/fabricjs-2';
import {FabricObject, classRegistry} from '@postermywall/fabricjs-2';

export const pmwFabricLineDefaultValues: Partial<TClassProperties<PMWFabricLine>> = {};

export enum LinePointsStyle {
  NONE = 0,
  STYLE_1 = 1,
  STYLE_2 = 2,
  STYLE_3 = 3,
  STYLE_4 = 4,
  STYLE_5 = 5,
  STYLE_6 = 6,
  STYLE_7 = 7,
  STYLE_8 = 8,
  STYLE_9 = 9,
}

export enum LineDash {
  NONE = 0,
  STYLE_1 = 1,
  STYLE_2 = 2,
  STYLE_3 = 3,
  STYLE_4 = 4,
}

interface UniquePMWFabricLineProps {
  lineEnd: LinePointsStyle;
  lineStart: LinePointsStyle;
  lineDash: LineDash;
  thickness: number;
}

export interface SerializedPMWFabricLineProps extends SerializedImageProps {}

export interface PMWFabricLineProps extends UniquePMWFabricLineProps {}

export class PMWFabricLine<
    Props extends TOptions<PMWFabricLineProps> = Partial<PMWFabricLineProps>,
    SProps extends SerializedPMWFabricLineProps = SerializedPMWFabricLineProps,
    EventSpec extends ObjectEvents = ObjectEvents,
  >
  extends FabricObject<Props, SProps, EventSpec>
  implements PMWFabricLineProps
{
  declare lineDash: LineDash;
  declare lineEnd: LinePointsStyle;
  declare lineStart: LinePointsStyle;
  declare thickness: number;

  static type = 'PMWFabricLine';

  static ownDefaults = pmwFabricLineDefaultValues;

  static getDefaults(): Record<string, any> {
    return {
      ...super.getDefaults(),
      ...PMWFabricLine.ownDefaults,
    };
  }

  /**
   * Constructor
   */
  constructor(options: Props) {
    super();
    Object.assign(this, PMWFabricLine.ownDefaults);
    this.setOptions(options);
  }

  _render(ctx: CanvasRenderingContext2D): void {
    const {width: w} = this;
    const x = -w / 2;
    const y = 0;

    ctx.lineWidth = this.thickness;
    this.setLineDash(ctx);
    ctx.strokeStyle = this.fill as string;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + this.width, y);
    ctx.stroke();
  }

  setLineDash(ctx: CanvasRenderingContext2D): void {
    switch (this.lineDash) {
      case LineDash.STYLE_1:
        ctx.setLineDash([3, 2]);
        break;
      case LineDash.STYLE_2:
        ctx.setLineDash([1, 3]);
        break;
      case LineDash.STYLE_3:
        ctx.setLineDash([5, 2]);
        break;
      case LineDash.STYLE_4:
        ctx.setLineDash([3, 1]);
        break;
      case LineDash.NONE:
        ctx.setLineDash([]);
        break;
      default:
        throw new Error(`Unknown lineDash: ${this.lineDash}`);
    }
  }
}

classRegistry.setClass(PMWFabricLine);
