import {Howl} from 'howler';
import {isChrome, isEdge, isMobile, isSafari} from 'react-device-detect';

/**
 * Whether the audio is locked or not. By default, audio on mobile browsers and Chrome/Safari is locked until a sound is played within a user interaction
 * @see https://github.com/goldfire/howler.js#mobilechrome-playback
 * @return {boolean}
 */
let audioUnlocked = !(isMobile || isChrome || isSafari || isEdge);

const SAMPLE_AUDIO_BASE_64 =
  'UklGRlIHAABXQVZFZm10IBAAAAABAAIARKwAABCxAgAEABAATElTVEIAAABJTkZPSUNSRAsAAAAyMDE0LTExLTA5AABJU0ZUDgAAAExhdmY1OC43Ni4xMDAASVRDSAwAAABMb2dpYyBQcm8gWABkYXRh5AYAAAAAAAAAA==';

/**
 * IMPORTANT: Need to call watchIsAudioPlayable asap for this to work
 */
export const isAudioPlayable = (): boolean => {
  return audioUnlocked;
};

export const watchIsAudioPlayable = (): void => {
  // Can't remove new because this is the only way howler allows you to detect audio unlock.
  // eslint-disable-next-line no-new
  new Howl({
    src: SAMPLE_AUDIO_BASE_64,
    // src: getAssetUrl('sample_audio.mp3'),
    // format: ['mp3'],
    onunlock: onAudioUnlocked,
  });
};

const onAudioUnlocked = (): void => {
  audioUnlocked = true;
};
/**
 * Returns whether the filetype is of a audio
 * @param {string} fileType
 * @return {boolean}
 */
export const isFiletypeAudio = (fileType: string): boolean => {
  if (fileType === '') {
    return false;
  }
  return fileType.indexOf('audio/') !== -1;
};
